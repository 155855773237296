import { useEffect, useState } from "react";
import "./App.css";
import {
  isLocalhost,
  isMobileDevice,
  isSupportedBrowser,
} from "./utils/deviceModel";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { userLocal } from "./services/localService/localService";
import { setAuthorization } from "./services/apiService/configURL";
import { detectIncognito } from "detectincognitojs";
import HomePage from "./pages/HomePage/HomePage";
import Login from "./pages/Login/Login";
import ConfirmOTP from "./pages/Login/ValidateOTP";
import Waiting from "./components/CountDraw/Waiting";
import CountDown from "./components/CountDraw/CountDown";
import ListGift from "./pages/ListGift/ListGift";
import ListGame from "./pages/ListGame/ListGame";
import InforCustomer from "./pages/Information/InforCustomer";
import ListGiftForCustomer from "./pages/ListGiftCustomer/ListGiftCustomer";
import ListHistory from "./pages/ListHistory/ListHistory";
import PrizeRules from "./pages/PrizeRule/PrizeRule";
import PlayGame from "./pages/PlayGame/PlayGame";
import NoAccessBrowser from "./pages/NoAccessBrowser/NoAccessBrowser";
import GuideTakeAPhoto from "./pages/GuideTakeAPhoto/GuideTakeAPhoto";

function App() {
  let access = false;
  document.body.style.backgroundColor = "white";
  let mobileDevice = isMobileDevice();
  let supportedBrowser = isSupportedBrowser();
  if ((mobileDevice && supportedBrowser) || isLocalhost()) {
    if (window.innerWidth < 440) {
      access = true;
    }
  }
  let { token } = userLocal.get();
  setAuthorization(token);
  const queryClient = new QueryClient();
  const [isPrivate, setIsPrivate] = useState(false);
  useEffect(() => {
    detectIncognito().then((result: any) => {
      if (result.isPrivate === false) {
        setIsPrivate(true);
      }
    });
  }, []);
  return (
    <div className="font-regular-so ">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {access && isPrivate ? (
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/games" element={<ListGame />} />
              <Route path="/video-game/:id" element={<PlayGame />} />
              <Route path="/prize" element={<PrizeRules />} />
              <Route path="/gifts" element={<ListGift />} />
              <Route path="/login" element={<Login />} />
              <Route path="/infor-customer" element={<InforCustomer />} />
              <Route path="/list-history" element={<ListHistory />} />
              <Route
                path="/list-infor-customer"
                element={<ListGiftForCustomer />}
              />
              <Route path="/validate-otp" element={<ConfirmOTP />} />
              <Route path="/waiting" element={<Waiting />} />
              <Route path="/countdown" element={<CountDown />} />
              {/* <Route path="/guide-takeaphoto" element={<GuideTakeAPhoto />} /> */}
            </Routes>
          </QueryClientProvider>
        </BrowserRouter>
      ) : (
        <NoAccessBrowser />
      )}
    </div>
  );
}

export default App;
