import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import HeaderBackground from "../../components/HeaderBackground/HeaderBackground";
import { GameServices } from "../../services/apiService/GameService";
import { userLocal } from "../../services/localService/localService";
import Logo from "../../components/NavBar/Logo";
import ContactPhoneZalo from "../../components/ContactPhoneZalo/ContactPhoneZalo";
import { ListReceiptServices } from "../../services/apiService/ListReceiveService";

const TITLE = "Thể lệ chương trình";
export default function PrizeRules() {
  const navigation = useNavigate();
  const [tc, setTC] = useState<any>();
  const handleTakePhoto = () => {
    // getData(1, "all");
  };
  let { token } = userLocal.get();

  const getDAta = (campaign_id: number, token: any) => {
    GameServices.GameDetailsApi(campaign_id, token)
      .then((res: any) => {
        console.log(res);
        localStorage.setItem("DATA_LEADER_BOARD", JSON.stringify(res));
        setTC(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getData = (page: number, reward_type: string) => {
    const data = {
      page: page,
      limit: 10,
      reward_type: reward_type,
    };
    ListReceiptServices.GetListMyGift(data)
      .then((res: any) => {
        console.log(res);
        navigation("/guide-takeaphoto");
      })
      .catch((err) => {
        localStorage.setItem("linkRedirect", `/guide-takeaphoto`);

        console.log(err);
      })
      .finally(() => {});
  };
  useEffect(() => {
    getDAta(1, token);
  }, []);
  return (
    <div>
      <HeaderBackground title={TITLE} buttonBack={`/`} />
      <div className="containerNotify__background bg-[#fff] absolute rounded-[30px_30px_0_0] bottom-0 h-[87%] w-full z-10">
        <div className="containerNotify__background-list font-light-mon box-border z-20">
          <div className=" box-border z-20 mx-6">
            <div
              className="font-regular-mon"
              dangerouslySetInnerHTML={{
                __html: tc?.content_details,
              }}
            ></div>
          </div>
          <div className="flex justify-center mb-10 box-border font-light-mon">
            <button
              className="bg-[#bdbdbd] font-bebas-mon px-8 py-2 rounded-2xl text-[24px] text-white"
              onClick={handleTakePhoto}
            >
              tham gia ngay
            </button>
          </div>
        </div>
      </div>
      <Logo />
      <ContactPhoneZalo />
    </div>
  );
}
